import React, { useEffect, useState } from "react"
import "./src/styles/global.css";
import DownloadPamplet from "./src/components/organisms/downloadPamphlet";
import ModalForms from "./src/components/organisms/modalForms";
import YoutubeVideoPlayer from "./src/components/organisms/videoPlayer";
import { FormType } from "./src/utils/formSubmission";
import { useLocation } from '@reach/router';
import DownloadTuition from './src/components/organisms/downloadTuition/index';
import { handleABTestingRedirect } from "./src/utils/functions";

export const onInitialClientRender = () => {
  handleABTestingRedirect()
}

const wrapPageElement = ({ element, props }) => {
  const location = useLocation()
  const [showForm, setShowForm] = useState<FormType | undefined>()
  const [showVideoPlayer, setShowVideoPlayer] = useState(false)

  useEffect(() => {
    const onShowForm = (e) => {
      setShowForm(e?.detail?.form)
    }
    const onPlayVideo = (e) => {
      setShowVideoPlayer(e?.detail?.show || false)
    }

    document.addEventListener('show-form', onShowForm)
    document.addEventListener('play-video', onPlayVideo)

    return () => {
      document.removeEventListener("show-form", onShowForm)
      document.removeEventListener('play-video', onPlayVideo)
    }
  }, [])

  useEffect(() => {
    setShowForm(undefined)
  }, [location])

  return (
    <div {...props}>
      {showForm === FormType.DOWNLOAD_PAMPHLET && <DownloadPamplet onClose={() => setShowForm(undefined)} />}
      {showForm === FormType.DOWNLOAD_TUITION && <DownloadTuition onClose={() => setShowForm(undefined)} />}
      {showForm === FormType.TRUST_US_A_PROJECT && <ModalForms formType={FormType.TRUST_US_A_PROJECT} onClose={() => setShowForm(undefined)} />}
      {showVideoPlayer && <YoutubeVideoPlayer onClose={() => setShowVideoPlayer(false)} />}
      {element}
    </div >
  )
}

export { wrapPageElement }