export const formatAirtablePhoneNumber = (phone: string): string =>
  `+243${phone.replaceAll('-', '')}`

export const getTrainingNameByKey = (key: string): string => {
  switch (key) {
    case 'smd':
      return 'Marketing Digital'
    case 'dev-web':
      return 'Développeur Web'
    default:
      return ''
  }
}

export const canDisplayPromotionalPrice = (trainingInfos: any) => {
  if (!trainingInfos?.fees?.promotional) return false
  if (trainingInfos?.fees?.promotionEndDate) {
    return new Date(trainingInfos?.fees?.promotionEndDate).getTime() > new Date().getTime()
  }
  return true
}

export const calculatePromotion = (trainingInfos: any) =>
  parseInt(trainingInfos.fees?.tuition, 10) - parseInt(trainingInfos.fees?.promotional, 10)

export const addLeading = (value: string | number, length: number, char: string = '0'): string => {
  const stringValue = value.toString()
  return stringValue.padStart(length, char)
}

export const handleABTestingRedirect = () => {
  const cookieName = 'ab_test_version'
  const versionBBaseUrl = 'https://admissions.kadea.academy'
  const currentPath = window.location.pathname
  const versionBUrl = `${versionBBaseUrl}${currentPath}`
  const cookieDurationDays = 14

  function getCookie(name: string) {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
    return match ? match[2] : null
  }

  function setCookie(name: string, value: string, days: number) {
    const expires = new Date()
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`
  }

  let assignedVersion = getCookie(cookieName)

  if (!assignedVersion) {
    assignedVersion = Math.random() < 0.5 ? 'A' : 'B'
    setCookie(cookieName, assignedVersion, cookieDurationDays)
  }

  if (assignedVersion === 'B' && window.location.href !== versionBUrl) {
    window.location.replace(versionBUrl)
  }
}
